import React from 'react'
import Seo from "~components/Seo"
import CartSection from '~components/sections/CartSection'
import CartItem from '~components/blocks/CartItem'
import { useCart } from '~context/siteContext'

const Cart = () => {
  const { cart, cartCount } = useCart()
  return (
    <>
      <Seo
        title="Your Bag"
				url="/bag"
      />
      <CartSection>
        {cart.map(cartItem => (
          <CartItem data={cartItem} />
        ))}
      </CartSection>
    </>
  )
}

export default Cart
